<template>
  <div class="record">
    <headbar :title="$t('理财记录')"></headbar>

    <div class="invest">
      <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :finished-text="$t('没有更多了')"
        :immediate-check="false" offset="10" @load="onLoad">
        <div class="invest-item" v-for="item in list" :key="item.id">
          <div class="item-top">
            <div>{{ $t('时间') }}</div>
            <div>{{ item.createTime }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('金额') }}：</div>
            <div>{{ item.amount }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('已释放金额') }}：</div>
            <div>{{ item.shifangTotal }}</div>
          </div>
          <div class="cell">
            <div>{{ $t('状态') }}：</div>
            <div>{{ item.stateName }}</div>
          </div>
          <div class="cell cell-btn">
            <div class="btn" @click="chezi(item.id)" v-if="item.state != 3">终止理财</div>
          </div>
        </div>
      </van-list>

      <empty v-if="list.length == 0"></empty>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { List } from "vant";
import CustomComponent from "@/components/CustomComponent";
import useClipboard from 'vue-clipboard3';

export default {
  name: '',
  components: {
    [List.name]: List,
  },
  setup() {
    return {
      us: ref(null),
      list: ref([]),
      pageIndex: ref(1),
      loading: ref(false),
      finished: ref(false),
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getdata()
    }
  },
  methods: {
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    copy(val) {
      let that = this
      const { toClipboard } = useClipboard()
      try {
        toClipboard(val)
        that.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ['Replicating Success'],
            persistent: true,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/LicaiOrder/List",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          state: 99
        },
        (res) => {
          _this.loading = false
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          if (data.length == 0) {
            _this.finished = true
          } else {
            _this.finished = false
          }
          _this.list.push(...data)
        }
      )
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    chezi(id) {
      let _this = this
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ["确定要终止理财吗?"],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        _this.$request.post(
          "api/LicaiOrder/Chezi",
          {
            token: _this.$utils.getloc("token"),
            userid: _this.us.userid,
            pageIndex: _this.pageIndex,
            licaiOrderId: id
          },
          (res) => {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            if (res.data.code == 100) {
              _this.pageIndex = 1
              _this.loading = false
              _this.finished = false
              _this.list = []
              _this.getdata()
            }
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.record {
  padding: 10px;
  min-height: 100vh;
}

.invest-item {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: #1B1B1B;
}

.cell {
  padding: 2px 0;
  display: flex;
  justify-content: space-between;
}

.cell-btn {
  display: flex;
  justify-content: flex-end;
}

.btn {
  margin-top: 8px;
  padding: 3px 15px;
  color: #000;
  font-size: 14px;
  text-align: center;
  background: #FFD585;
  border-radius: 6px;
}

.item-top {
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(48, 45, 45)
}
</style>